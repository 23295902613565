(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
var CIC = require('../../sdk/CrossIframeCommunication.js');
var config = require('../../sdk/config.js');

module.exports = IframeWrapper;

var url = {
    playerIFrame: config.appDomain + '/sdk/player/',
    p2pPlayerIFrame: config.appDomain + '/sdk/p2pplayer/',
    chatIFrame: config.appDomain + '/sdk/chat/',
};
var validQueryProperties = [
    'id',
    'offsets',
    'embed',
    'autoplay',
    'start',
    'repeat',
    'display',
    'identificationToken',
    'dvr',
    'language',
    'customBtnColor',
    'customVideoAndSlideBackgroundColor',
    'newWindow',
    'debug',
    'emit',
    'textTrack',
    'skipWebcastLink',
    'pipeline',
    'source',
];

function IframeWrapper(options) {
    var _this = this;
    options.id = options.id.replace('/', '_');

    if (options.version < 2) {
        if (options.display !== undefined) {
            if (isNaN(options.display)) options.display = 0;
            options.display += 16 + 32 + 64;
        }
    }

    var cic = new CIC(options.id);



    this.element = options.element || createPlayerIframe();
    this.on = on;
    this.emit = cic.emit;
    this.subscribeIframe = cic.subscribeIframe;
    this.seek = seek;
    this.createChat = createChat;
    this.destroy = destroy;
    this.element.src = createPlayerUrl();

    this.playPause = {
        state: 'unknown',
        toggle: function () {
            _this.emit(_this.playPause.state == _this.playPause.states.playing ? 'pause' : 'play');
        },
    };

    cic.subscribeIframe(this.element);

    cic.on('playpause.state:change', function (e) {
        _this.playPause.state = e.state;
        _this.playPause.states = e.states;
    });

    function createChat() {
        var chat = createChatIframe();
        cic.subscribeIframe(chat);
        return chat;
    }

    function on(event, callback) {

        if (options.version < 2) {
            return onv1(event, function (e) {
                callback(translateEvent(e));
            });
        }

        return cic.on(event, function (e) {
            callback(translateEvent(e));
        });
    }

    function translateEvent(e) {
        if (e && e.event) {
            if (e.event.intent == 'index.index:activate') e.event.intent = 'topics.topic:activate';
            if (e.event.intent == 'index.index:deactivate') e.event.intent = 'topics.topic:deactivate';
            if (e.event.intent == 'speakers.hide:deactivate') e.event.intent = 'speakers.speaker:deactivate';
            if (e.event.intent == 'dcnspeakers.speaker:activate') e.event.intent = 'speakers.speaker:activate';
            if (e.event.intent == 'dcnspeakers.speaker:deactivate') e.event.intent = 'speakers.speaker:deactivate';
            if (e.event.intent == 'dcnspeakers.hide:deactivate') e.event.intent = 'speakers.speaker:deactivate';
        }
        return e;
    }

    function onv1(event, callback) {
        switch (event) {
            case 'cwc.sdk.player.resource.create':
                return cic.on('resource:create', callback);
            case 'cwc.sdk.player.resource.properties.change':
                return cic.on('resource.state:change', function (e) {
                    callback({
                        resource: e.resource,
                        properties: {
                            isActive: e.isActive,
                        },
                        view: 'current',
                    });
                });
        }
    }




    function createPlayerIframe() {
        var element = options.element || document.createElement('iframe');
        element.src = createPlayerUrl();
        element.style.width = '100%';
        element.style.height = '100%';
        element.style.border = '0';
        element.id = options.id;
        element.setAttribute('allowfullscreen', 'true');
        element.setAttribute('webkitallowfullscreen', 'true');
        element.setAttribute('mozallowfullscreen', 'true');
        element.setAttribute('oallowfullscreen', 'true');
        element.setAttribute('msallowfullscreen', 'true');
        return element;
    }

    function createChatIframe() {
        var element = document.createElement('iframe');
        element.style.width = '100%';
        element.style.height = '100%';
        element.style.border = '0';
        element.src = createChatUrl();

        return element;
    }

    function seek(options) {
        cic.postMessage(_this.element.contentWindow, 'seek', options);
    }

    function createPlayerUrl() {

        var string = options.p2p == undefined ? options.playerIFrame || url.playerIFrame : options.p2pPlayerIFrame || url.p2pPlayerIFrame;

        var first = true;
        var hasOptions = false;
        for (var key in options) {

            if (validQueryProperties.indexOf(key) == -1) continue;
            if ((key == 'autoplay' || key == 'repeat') && !options[key]) continue;

            hasOptions = true;

            string += first ? '?' : '&';
            string += key;

            switch (key) {
                case 'offsets':
                    string += '=' + getOffsets(options[key]);
                    break;
                case 'id':
                    string += '=' + encodeURIComponent(options[key].replace('/', '_'));
                    break;
                case 'autoplay':
                case 'repeat':
                case 'debug':
                case 'skipWebcastLink':
                    break;
                default:
                    string += '=' + options[key];
                    break;
            }

            first = false;
        }

        first = true;
        var query = getQueryParameters();
        for (var key in query) {
            if (key.indexOf('utm') == 0) {
                string += first && !hasOptions ? '?' : '&';
                string += key + '=' + query[key];
                first = false;
            }
        }

        return string;
    }

    function getOffsets(offsets) {
        var first = true;
        var string = '';
        for (var i = 0, length = offsets.length; i < length; i++) {
            if (!first) {
                string += ','
            }

            string += offsets[i];
            first = false;
        }
        return string;
    }

    function destroy() {
        cic.destroy();
    }

    function createChatUrl() {
        return url.chatIFrame
            + '?id=' + options.id;
    }
}

function getQueryParameters(str) {
    return (str || document.location.search).replace(/(^\?)/, '').split("&").map(function (n) { return n = n.split("="), this[n[0]] = decodeURIComponent(n[1]), this }.bind({}))[0];
}
},{"../../sdk/CrossIframeCommunication.js":3,"../../sdk/config.js":5}],2:[function(require,module,exports){
var IframeWrapper = require('./IFrameWrapper.js');
var displayOptions = require('../../sdk/player/displayOptions.js');

window.cwc = window.cwc || {};
window.cwc.sdk = window.cwc.sdk || {};
window.cwc.sdk.player = window.cwc.sdk.player || {};

window.cwc.sdk.player.client = {
    createPlayer: function (options) {
        options.version = 2;
        return createPlayer(options);
    },
    displayOptions: displayOptions,
}

window.cwc.iframe = window.cwc.iframe || {};
window.cwc.iframe.player = window.cwc.iframe.player || {};
window.cwc.iframe.player.client = {
    create: function (options) {
        options.version = 1;
        return createPlayer(options);
    },
    displayOptions: displayOptions,
}

function createPlayer(options) {
    return new IframeWrapper(options);
}

module.exports = {
    create: function (options) {
        options.version = 2;
        return createPlayer(options);
    }
}
},{"../../sdk/player/displayOptions.js":7,"./IFrameWrapper.js":1}],3:[function(require,module,exports){
var EventEmitter = require('./EventEmitter.js');
var guid = require('./guid.js');

module.exports = CrossIframeCommunication;


function CrossIframeCommunication(key) {
    var _this = this;
    var eventEmitter = new EventEmitter(true);
    var iFrames = [];
    var parents = [];

    this.id = guid.v4();
    this.postMessage = postMessage;
    this.emit = emit;
    this.on = eventEmitter.on;
    this.one = eventEmitter.on;
    this.subscribeIframe = subscribeIframe;
    this.destroy = destroy;

    function postMessage(target, event, data) {
        var message = {
            sender: _this.id,
            key: key,
            event: event,
            data: data,
        };

        try {
            if (target) target.postMessage(message, '*');
        } catch (e) {
            console.log(e, message);
        }
    }

    function emit(event, data) {

        for (var i = 0, length = iFrames.length; i < length; i++) {
            postMessage(iFrames[i].contentWindow, event, data);
        }

        for (var i = 0, length = parents.length; i < length; i++) {
            postMessage(parents[i], event, data);
        }

        return eventEmitter.emit(event, data);
    }

    window.addEventListener('message', onMessage);

    postMessage(window.opener || window.parent, 'ready');

    function onMessage(e) {
        try {
            var obj = e.data;

            if (!obj.key || obj.key == key && obj.sender != _this.id) {
                emit(obj.event, obj.data);
            }
        } catch (e) {
            console.error(e);
            //nomnomnom
        }
    }

    function subscribeIframe(element) {
        iFrames.push(element);
    }

    function subscribeParent(element) {
        parents.push(element);
    }

    function destroy() {
        window.removeEventListener('message', onMessage);
        iFrames = null;
        parents = null;
        eventEmitter = null;
    }
}


},{"./EventEmitter.js":4,"./guid.js":6}],4:[function(require,module,exports){
/**
@class
@alias cwc.sdk.EventEmitter
@description emit events
@constructor
@property {object} events list of registered events
*/
function EventEmitter(storeEvents) {
    var _this = this;

    this.events = {};
    this.callbacks = {};
    this.emit = emit;
    this.on = on;
    this.one = one;
    this.destroy = destroy;

    /**
    @method
    @alias cwc.sdk.EventEmitter.emit
    @description emit an event
    @param {string} event name of event
    @param {object} data will be passed to event listeners
    @return {array} results
    */
    function emit(event, data) {

        if (storeEvents) {
            var events = _this.events[event];
            if (!events) {
                events = [];
                _this.events[event] = events;
            }
            events.push(data);
        }

        var callbacks = _this.callbacks[event];
        var results = [];
        if (callbacks) {
            for (var i = callbacks.length - 1; i >= 0; --i) {
                var callback = callbacks[i];
                results.push(callback(data));
                if (callback.once) {
                    callback.remove();
                }
            }
        }
        return results;
    }

    /**
    @method
    @alias cwc.sdk.EventEmitter.on
    @description listen to an event
    @param {string} event name of event
    @param {function} callback will be called when an event is emitted
    @return {function} cancelToken calling this function will unregister this listener
    */
    function on(event, callback, once) {
        var callbacks = _this.callbacks[event];
        if (!callbacks) {
            callbacks = [];
            _this.callbacks[event] = callbacks;
        }

        callbacks.push(callback);

        callback.once = once;
        callback.remove = function () {
            var index = callbacks.indexOf(callback);
            if (index > -1) callbacks.splice(index, 1);
        }

        if (storeEvents) {
            var events = _this.events[event];
            if (events) {
                for (var i = 0, length = events.length; i < length; i++) {
                    callback(events[i]);
                    if (once) {
                        callback.remove();
                        break;
                    }
                }
            }
        }

        return callback.remove;
    }

    /**
    @method
    @alias cwc.sdk.EventEmitter.one
    @description listen to an event once
    @param {string} event name of event
    @param {function} callback will be called when an event is emitted (one time only)
    */
    function one(event, callback) {
        return on(event, callback, true);
    }

    function destroy() {
        _this.events = {};
    }
}


module.exports = EventEmitter;
},{}],5:[function(require,module,exports){
var isLocal = getLocal();
var isStaging = getStaging();
var isAdmin = getAdmin();
var domain = getDomain(isLocal, isStaging, isAdmin);
var clientDomain = getDomain(isLocal, isStaging, false);
var adminDomain = getDomain(isLocal, isStaging, false);
var appDomain = getAppDomain(isLocal, isStaging, isAdmin);
var reportDomain = getDomain(isLocal, isStaging, false);
var adminDomain = getDomain(isLocal, isStaging, true);

var stack = isLocal || isStaging ? 'staging' : 'production';

var config = {
    isAdmin: isAdmin,
    domain: domain,
    clientDomain: clientDomain,
    adminDomain: adminDomain,
    appDomain: appDomain,
    reportDomain: reportDomain,
    stack: stack,
    src: {
        firebase: 'https://www.gstatic.com/firebasejs/6.6.2/firebase.js',
        analytics: '//www.google-analytics.com/analytics.js',
    },
    widgets: {
        widgetUrl: appDomain + '/sdk/widgets/{widget}?id={id}',
    },
    firebase: {
        events: getBlunderboreEventsFirebaseConfig(isLocal, isStaging),
        chat: getChatFirebaseConfig(isLocal, isStaging),
        live: getBlunderboreLiveFirebaseConfig(isLocal, isStaging),
    },
    recaptcha: {
        siteKey: '6LdOAKIUAAAAAJCtnneL1ffRBCsHCo_FNuFGcCyi',
        url: 'https://www.google.com/recaptcha/api.js?render=explicit',
    },
    url: {
        configuration: adminDomain + '/configurations',
        //configuration: domain + '/configurations',
        player: domain + '/players/{id}/info',
        accessRules: domain + '/accessrules/{id}',
        refreshToken: domain + '/accessrules/token?key={token}',
        survey: domain + '/accessrules/portal/{id}',
        resource: domain + '/players/{webcastId}/resources/{resourceId}',
        share: {
            facebook: 'http://www.facebook.com/sharer.php?u={url}',
            twitter: 'https://twitter.com/share?url={url}',
            linkedin: 'http://www.linkedin.com/shareArticle?url={url}'
        },
        fireChatToken: domain + '/firebase',
        fireChat: 'https://scorching-fire-2945.firebaseio.com/chat/',
        askAQuestion: 'https://scorching-fire-2945.firebaseio.com/chat/room-messages/{roomId}.json?auth={token}',
        p2pUsage: domain + '/players/{id}/p2p/usage',
        chatHistory: domain + '/players/{id}/chat/history/',
        error: domain + '/report/error',
        viewers: reportDomain + '/players/{id}/report',
        vtt: {
            admin: {
                get: adminDomain + '/players/{id}/vtt/{path}/{language}',
                list: adminDomain + '/players/{id}/vtt/',
            },
            get: domain + '/players/{id}/vtt/{path}/{language}',
            list: domain + '/players/{id}/vtt/',
        },
        auth: {
            token: domain + '/auth/token',
            provider: domain + '/auth/api/{provider}?customerCode={customerCode}&successRedirect={successRedirect}&failureRedirect={failureRedirect}',
            successRedirect: appDomain + '/sdk/auth/identity/callback.html?success&identificationToken={token}',
            failureRedirect: appDomain + '/sdk/auth/identity/callback.html?failure',
        },
        slides: {
            upload: 'https://cwc-thumbs.s3.amazonaws.com/',
            get: 'https://vwsp5tdc06.execute-api.eu-west-1.amazonaws.com/' + stack + '/slides/{webcastId}',
            convert: 'https://vwsp5tdc06.execute-api.eu-west-1.amazonaws.com/' + stack + '/slides/{webcastId}/convert',
            remove: 'https://vwsp5tdc06.execute-api.eu-west-1.amazonaws.com/' + stack + '/slides/{webcastId}/delete',
            uploadCredentialsForm: 'https://vwsp5tdc06.execute-api.eu-west-1.amazonaws.com/' + stack + '/slides/{webcastId}/upload/credentials/form',
        },
        events: {
            admin: {
                list: adminDomain + '/players/{id}/eventsv2/list',
                //get: adminDomain + '/events/{id}',
                //post: adminDomain + '/events',
            },
            list: domain + '/players/{id}/eventsv2/list',
            //get: domain + '/events/{id}',
        },
        rivet: {
            audience: {
                conference: getRivetAudienceConferenceUrl(isLocal, isStaging, isAdmin)
            },
            proxy: '//rivet-proxy-{environment}.companywebcast.com',
        }
    },
    activeResourceViews: ['cwc.legacy.speaker', 'cwc.legacy.form', 'cwc.legacy.slide', 'cwc.legacy.topic'],
    resources: [
        {
            'name': 'speakers',
            'events': {
                'speakers.speaker:activate': true,
                'speakers.speaker:deactivate': false,
                'speakers.hide:deactivate': false,
                'dcnspeakers.speaker:activate': true,
                'dcnspeakers.hide:deactivate': false
            }
        },
        {
            'name': 'topics',
            'events': {
                'index.index:activate': true,
                'index.index:deactivate': false
            }
        },
        {
            'name': 'comments',
            'events': {
                'comments.comment:activate': true,
                'comments.hide:deactivate': false
            }
        },
        {
            'name': 'forms',
            'events': {
                'form.show:activate': true,
                'form.hide:deactivate': false
            }
        },
        {
            name: 'slides',
            events: {
                'slides.slide:activate': true,
                'slides.slide:deactivate': false,
            }
        }
    ],
    cast: {
        applicationId: isLocal || isStaging ? 'C0C9EDFF' : 'CD5FD6DC',
        urn: 'urn:x-cast:cwc.sdk.player',
    },
}

module.exports = config;

function getRivetAudienceConferenceUrl(isLocal, isStaging, isAdmin) {
    var appBaseUrl = (isLocal || isStaging)
        ? 'https://audienceconference.devrivet.net/{eventId}'
        : 'https://idontexistyet.net/{eventId}';

    return appBaseUrl + (isAdmin ? '/participant' : '/attendee');
}

function getDomain(isLocal, isStaging, isAdmin) {
    return '//sdk{admin}{staging}.companywebcast.com'
        .replace('{admin}', isAdmin ? '-admin' : '')
        .replace('{staging}', isLocal || isStaging ? '-staging' : '');
}

function getAppDomain(isLocal, isStaging, isAdmin) {
    if (isLocal) return '//localhost';
    return getDomain(isLocal, isStaging, isAdmin);
}

function getLocal() {
    return false;
}
function getStaging() {
    return false;
}
function getAdmin() {
    return false;
}

function getChatFirebaseConfig(isLocal, isStaging) {
    if (isLocal || isStaging) {
        return {
            token: domain + '/firebase',
            apiKey: "AIzaSyDTVlszjL3F67u0QjsZE6NP4OOpdCXEKbs",
            authDomain: "staging-chat-6c7df.firebaseapp.com",
            databaseURL: "https://staging-chat-6c7df.firebaseio.com",
            projectId: "staging-chat-6c7df",
            storageBucket: "staging-chat-6c7df.appspot.com",
            messagingSenderId: "866129312965",
            appId: "1:866129312965:web:da32e7554d11c1bd"
        }
    }

    return {
        token: domain + '/firebase',
        apiKey: "AIzaSyCkJHYk4uyrxBwtFm6dEoxSwsRO68uRxuU",
        authDomain: "scorching-fire-2945.firebaseapp.com",
        databaseURL: "https://scorching-fire-2945.firebaseio.com",
        projectId: "scorching-fire-2945",
        storageBucket: "scorching-fire-2945.appspot.com",
        messagingSenderId: "789939780219",
        appId: "1:789939780219:web:37a181686cb0fd4a"
    };
}

function getBlunderboreEventsFirebaseConfig(isLocal, isStaging) {
    if (isLocal || isStaging) {
        return {
            token: domain + "/accessrules/firebase/events",
            apiKey: "AIzaSyAoB5iGAGK3HEOH38C6HffCeYwaMEi2bhc",
            authDomain: "blunderbore-live-development.firebaseapp.com",
            databaseURL: "https://blunderbore-live-development.firebaseio.com",
            projectId: "blunderbore-live-development",
            storageBucket: "blunderbore-live-development.appspot.com",
            messagingSenderId: "551303059169"
        }
    }

    return {
        token: domain + "/accessrules/firebase/events",
        apiKey: "AIzaSyB1uBWs6TbIdT85w218DLGLWAjO1PwpKUA",
        authDomain: "blunderbore-live.firebaseapp.com",
        databaseURL: "https://blunderbore-live.firebaseio.com",
        projectId: "blunderbore-live",
        storageBucket: "blunderbore-live.appspot.com",
        messagingSenderId: "896486049217"
    }
}

function getBlunderboreLiveFirebaseConfig(isLocal, isStaging) {
    if (isLocal || isStaging) {
        return {
            apiKey: "AIzaSyAoB5iGAGK3HEOH38C6HffCeYwaMEi2bhc",
            authDomain: "blunderbore-live-development.firebaseapp.com",
            databaseURL: "https://blunderbore-live-development.firebaseio.com",
            projectId: "blunderbore-live-development",
            storageBucket: "blunderbore-live-development.appspot.com",
            messagingSenderId: "551303059169",
            appId: "1:551303059169:web:7f4d4b5944660e76"
        };
    }

    return {
        apiKey: "AIzaSyB1uBWs6TbIdT85w218DLGLWAjO1PwpKUA",
        authDomain: "blunderbore-live.firebaseapp.com",
        databaseURL: "https://blunderbore-live.firebaseio.com",
        projectId: "blunderbore-live",
        storageBucket: "blunderbore-live.appspot.com",
        messagingSenderId: "896486049217",
        appId: "1:896486049217:web:776e4952f6187cb2"
    };

}
},{}],6:[function(require,module,exports){
module.exports = {
    v4: v4,
}

function v4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

},{}],7:[function(require,module,exports){
var displayOptions = {
    home: 1,
    topics: 2,
    social: 4,
    embed: 8,
    forms: 16,
    askAQuestion: 32,
    controls: 64,    
}

var all = 0;

for (var key in displayOptions) {
    all += displayOptions[key];
}

displayOptions.all = all;

module.exports = displayOptions;
},{}]},{},[2]);
